import { SvgIcon } from "@mui/material";
import { red } from '@mui/material/colors';
import fbLogo from "../../assets/fb-logo.png";
import { ReactComponent as ConquestIcon } from "../../assets/conquest-icon.svg";
import { FB_DIRECTIVES } from "./directives";
import MainGameMenu from "../../components/game/MainGameMenu";
import FactionsGrid from "../../components/faction/FactionsGrid";
import { QRCODES } from "../qrcodes";
import { QR_DIRECTIVES } from "../qrdirectives";
import FactionLayout from "../../components/faction/FactionLayout";
import ListLayout from "../../components/list/ListLayout";
import FactionPanel from "../../components/faction/FactionPanel";
import EntryPanel from "../../components/entry/EntryPanel";
import ListPanel from "../../components/list/fb/ListPanel";
import ListEntryPanel from "../../components/list-entry/ListEntryPanel";
import DetailedOptions from "../../components/entry/options/DetailedOptions";
import DescriptionOnly from "../../components/entry/options/DescriptionOnly";
import DetailedList from "../../components/list-entry/options/DetailedList";
import baseIcon from "../../assets/base-icon.svg";
import FB_RULES from "./rules";
import FB_TARGETS from "./targets";
import FB_EFFECTS from "./effects";
import hundredCardBgr from "../../assets/hundred-512.jpg";
import spiresCardBgr from "../../assets/spires-512.jpg";
import dweghomCardBgr from "../../assets/dweghom-512.jpg";
import nordsCardBgr from "../../assets/nords-512.jpg";
import wadrhunCardBgr from "../../assets/wadrhun-512.jpg";
import dominionCardBgr from "../../assets/dominion-512.jpg";
import statesCardBgr from "../../assets/states-512.jpg";
import sorcerersCardBgr from "../../assets/sorcerers-512.jpg";
import dominionBgr from "../../assets/dominion-720.jpg";
import { ReactComponent as HundredIcon } from "../../assets/hundred-icon.svg";
import { ReactComponent as SpiresIcon } from "../../assets/spires-icon.svg";
import { ReactComponent as DweghomIcon } from "../../assets/dweghom-icon.svg";
import { ReactComponent as NordsIcon } from "../../assets/nords-icon.svg";
import { ReactComponent as WadrhunIcon } from "../../assets/wadrhun-icon.svg";
import { ReactComponent as StatesIcon } from "../../assets/states-icon.svg";
import { ReactComponent as DominionIcon } from "../../assets/dominion-icon.svg";
import { ReactComponent as SorcerersIcon } from "../../assets/sorcerer-kings-icon.svg";

import Retinue from "../../components/entry/options/Retinue";
import RetinueFB from "../../components/list-entry/options/RetinueFB";

import hundredDefault from "../../assets/portraits/100k-default.png";
import chapterMage from "../../assets/portraits/chapter-mage.jpg";
import gildedLegion from "../../assets/portraits/gilded-legion.jpg";
import householdGuard from "../../assets/portraits/household-guard.jpg";
import householdKnights from "../../assets/portraits/household-knights.jpg";
import hunterCadre from "../../assets/portraits/hunter-cadre.jpg";
import imperialOfficer from "../../assets/portraits/imperial-officer.jpg";
import longbowmen from "../../assets/portraits/longbowmen.jpg";
import menAtArms from "../../assets/portraits/men-at-arms.jpg";
import crossbowmen from "../../assets/portraits/mercenary-crossbowmen.jpg";
import militia from "../../assets/portraits/militia.jpg";
import militiaBowmen from "../../assets/portraits/militia-bowmen.jpg";
import mountedNobleLord from "../../assets/portraits/mounted-noble-lord.jpg";
import mountedSquires from "../../assets/portraits/mounted-squires.jpg";
import nobleLord from "../../assets/portraits/noble-lord.jpg";
import steelLegion from "../../assets/portraits/steel-legion.jpg";
import theistPriest from "../../assets/portraits/theist-priest.jpg";
import armsmaster from "../../assets/portraits/armsmaster.jpg";
import drillmaster from "../../assets/portraits/drillmaster.jpg";
import seasonedVeteran from "../../assets/portraits/seasoned-veteran.jpg";
import servite from "../../assets/portraits/servite.jpg";
import nullMage from "../../assets/portraits/null-mage.jpg";
import imperialRangers from "../../assets/portraits/imperial-ranger-corps.jpg";
import prioryTower from "../../assets/portraits/priory-commander-crimson-tower.jpg";
import orderTower from "../../assets/portraits/order-of-the-crimson-tower.jpg";
import errantShield from "../../assets/portraits/errant-of-the-order-of-the-shield.jpg";
import orderDawn from "../../assets/portraits/order-of-the-ashen-dawn.jpg";
import neophyte from "../../assets/portraits/neophyte.jpg";
import sealedTemple from "../../assets/portraits/sealed-temple.jpg";

import spiresDefault from "../../assets/portraits/spires-default.png";
import centaur from "../../assets/portraits/centaur-avatara.jpg";
import forceGrown from "../../assets/portraits/force-grown-drones.jpg";
import executor from "../../assets/portraits/high-clone-executor.jpg";
import sentinel from "../../assets/portraits/incarnate-sentinels.jpg";
import highborn from "../../assets/portraits/lineage-highborne.jpg";
import marksman from "../../assets/portraits/marksman-clones.jpg";
import assassin from "../../assets/portraits/mimetic-assassin.jpg";
import pheromancer from "../../assets/portraits/pheromancer.jpg";
import stryx from "../../assets/portraits/stryx.jpg";
import vanguard from "../../assets/portraits/vanguard-clones.jpg";
import vanguardInfiltrators from "../../assets/portraits/vanguard-clone-infiltrators.jpg";
import preceptor from "../../assets/portraits/ward-preceptor.jpg";
import abomination from "../../assets/portraits/abomination.jpg";
import assault from "../../assets/portraits/assault-preceptor.jpg";
import avatara from "../../assets/portraits/avatara.jpg";
import biomancer from "../../assets/portraits/biomancer.jpg";
import bruteDrones from "../../assets/portraits/brute-drones.jpg";
import catabolic from "../../assets/portraits/catabolic-node.jpg";
import onslaughtDrones from "../../assets/portraits/onslaught-drones.jpg";
import boundClones from "../../assets/portraits/bound-clones.jpg";
import siegebreaker from "../../assets/portraits/siegebreaker-behemoth.jpg";
import lineagePrideborne from "../../assets/portraits/lineage-prideborne.jpg";
import desolationDrones from "../../assets/portraits/desolation-drones.jpg";
import leonineAvatara from "../../assets/portraits/leonine-avatara.jpg";

import nordsDefault from "../../assets/portraits/nords-default.png";
import ugr from "../../assets/portraits/ugr.jpg";
import captain from "../../assets/portraits/captain.jpg";
import huskarls from "../../assets/portraits/huskarls.jpg";
import jarl from "../../assets/portraits/jarl.jpg";
import mountainJotnar from "../../assets/portraits/mountain-jotnar.jpg";
import fenr from "../../assets/portraits/fenr-beastpack.jpg";
import iceJotnar from "../../assets/portraits/ice-jotnar.jpg";
import konungyr from "../../assets/portraits/konungyr.jpg";
import skald from "../../assets/portraits/skald.jpg";
import trolls from "../../assets/portraits/trolls.jpg";
import raiders from "../../assets/portraits/raiders.jpg";
import shaman from "../../assets/portraits/shaman.jpg";
import shieldBiter from "../../assets/portraits/shield-biter.jpg";
import stalkers from "../../assets/portraits/stalkers.jpg";
import blooded from "../../assets/portraits/blooded-nords.jpg";
import bowChosen from "../../assets/portraits/bow-chosen.jpg";
import valkyries from "../../assets/portraits/valkyries.jpg";
import volva from "../../assets/portraits/volva.jpg";
import seaJotnar from "../../assets/portraits/sea-jotnar.jpg";
import ulfhednar from "../../assets/portraits/ulfhednar.jpg";
import bearsarks from "../../assets/portraits/bearsarks.jpg";
import thegn from "../../assets/portraits/thegn.jpg";
import savage from "../../assets/portraits/savage.jpg";
import werewolves from "../../assets/portraits/werewolves.jpg";
import vargyrLord from "../../assets/portraits/vargyr-lord.jpg";
import prometheanSeidr from "../../assets/portraits/promethean-seidr.jpg";

import dweghomDefault from "../../assets/portraits/dweghom-default.png";
import holdWarriors from "../../assets/portraits/hold-warriors.jpg";
import automata from "../../assets/portraits/inferno-automata.jpg";
import initiates from "../../assets/portraits/initiates.jpg";
import sorcerer from "../../assets/portraits/tempered-sorcerer.jpg";
import steelshaper from "../../assets/portraits/tempered-steelshaper.jpg";
import wardens from "../../assets/portraits/wardens.jpg";
import kerawegh from "../../assets/portraits/ardent-kerawegh.jpg";
import dragonslayers from "../../assets/portraits/dragonslayers.jpg";
import fireforged from "../../assets/portraits/fireforged.jpg";
import berserkers from "../../assets/portraits/flame-berserkers.jpg";
import flamecaster from "../../assets/portraits/flamecaster.jpg";
import drake from "../../assets/portraits/hellbringer-drake.jpg";
import hellbringerSorcerer from "../../assets/portraits/hellbringer-sorcerer.jpg";
import heraldFire from "../../assets/portraits/herald-of-fire.jpg";
import heraldStone from "../../assets/portraits/herald-of-stone.jpg";
import heraldMagma from "../../assets/portraits/herald-of-magma.jpg";
import ballistae from "../../assets/portraits/hold-ballistae.jpg";
import raegh from "../../assets/portraits/hold-raegh.jpg";
import thanes from "../../assets/portraits/hold-thanes.jpg";
import exemplar from "../../assets/portraits/exemplar.jpg";
import apprentice from "../../assets/portraits/mnemancer-apprentice.jpg";
import steelforged from "../../assets/portraits/steelforged.jpg";
import ironcladDrake from "../../assets/portraits/ironclad-drake.jpg";
import bergontRaegh from "../../assets/portraits/bergont-raegh.jpg";
import magmaforged from "../../assets/portraits/magmaforged.jpg";
import stoneforged from "../../assets/portraits/stoneforged.jpg";

import wadrhunDefault from "../../assets/portraits/wadrhun-default.png";
import queen from "../../assets/portraits/matriarch-queen.jpg";
import predator from "../../assets/portraits/predator.jpg";
import raptors from "../../assets/portraits/raptor-riders.jpg";
import scion from "../../assets/portraits/scion-of-conquest.jpg";
import slingers from "../../assets/portraits/slingers.jpg";
import warbred from "../../assets/portraits/warbred.jpg";
import aberration from "../../assets/portraits/aberration.jpg";
import apexPredator from "../../assets/portraits/apex-predator.jpg";
import bloodedWadrhun from "../../assets/portraits/blooded-wadrhun.jpg";
import braves from "../../assets/portraits/braves.jpg";
import hunters from "../../assets/portraits/hunters.jpg";
import huntingPack from "../../assets/portraits/hunting-pack.jpg";
import veterans from "../../assets/portraits/veterans.jpg";
import chieftain from "../../assets/portraits/chieftain.jpg";
import apexQueen from "../../assets/portraits/apex-queen.jpg";
import chosenConquest from "../../assets/portraits/chosen-of-conquest.jpg";
import thunderChieftain from "../../assets/portraits/thunder-chieftain.jpg";
import thunderRiders from "../../assets/portraits/thunder-riders.jpg";

import dominionDefault from "../../assets/portraits/dominion-default.png";
import archimandrite from "../../assets/portraits/archimandrite.jpg";
import golems from "../../assets/portraits/bone-golems.jpg";
import divinity from "../../assets/portraits/fallen-divinity.jpg";
import hierodeacon from "../../assets/portraits/hierodeacon.jpg";
import kataphraktoi from "../../assets/portraits/kataphraktoi.jpg";
import kheres from "../../assets/portraits/kheres.jpg";
import moroi from "../../assets/portraits/moroi.jpg";
import optio from "../../assets/portraits/optio.jpg";
import praetorian from "../../assets/portraits/praetorian-guard.jpg";
import strategos from "../../assets/portraits/strategos.jpg";
import xhiliarch from "../../assets/portraits/xhiliarch.jpg";
import darkCenotaph from "../../assets/portraits/dark-cenotaph.jpg";
import legionnaires from "../../assets/portraits/legionnaires.jpg";
import mountedStrategos from "../../assets/portraits/mounted-strategos.jpg";
import kanephors from "../../assets/portraits/kanephors.jpg";
import karyatids from "../../assets/portraits/karyatids.jpg";
import athanatoi from "../../assets/portraits/athanatoi.jpg";
import varangianGuard from "../../assets/portraits/varangian-guard.jpg";
import reliquary from "../../assets/portraits/profane-reliquary.jpg";
import hetairos from "../../assets/portraits/hetairos.jpg";
import buccephaloi from "../../assets/portraits/buccephaloi.jpg";
import cultists from "../../assets/portraits/cultists.jpg";
import hashashin from "../../assets/portraits/hashashin.jpg";
import centaurKerykes from "../../assets/portraits/centaur-kerykes.jpg";
import centaurProdromoi from "../../assets/portraits/centaur-prodromoi.jpg";

import statesDefault from "../../assets/portraits/states-default.png";
import haspists from "../../assets/portraits/minotaur-haspists.jpg";
import hoplites from "../../assets/portraits/hoplites.jpg";
import phalangites from "../../assets/portraits/phalangites.jpg";
import polemarch from "../../assets/portraits/polemarch.jpg";
import thyreans from "../../assets/portraits/minotaur-thyreans.jpg";
import aristarch from "../../assets/portraits/aristarch.jpg";
import mechanist from "../../assets/portraits/mechanist.jpg";
import agema from "../../assets/portraits/agema.jpg";
import hephaestian from "../../assets/portraits/hephaestian.jpg";
import promethean from "../../assets/portraits/promethean.jpg";
import thorakites from "../../assets/portraits/thorakites.jpg";
import dorilates from "../../assets/portraits/dorilates.jpg";
import lochagos from "../../assets/portraits/lochagos.jpg";
import eidolon from "../../assets/portraits/eidolon.jpg";
import inquisitors from "../../assets/portraits/inquisitors.jpg";
import satyroi from "../../assets/portraits/satyroi.jpg";
import selinoi from "../../assets/portraits/selinoi.jpg";
import companionCavalry from "../../assets/portraits/companion-cavalry.jpg";
import ipparchos from "../../assets/portraits/ipparchos.jpg";
import talos from "../../assets/portraits/talos.jpg";
import skorpios from "../../assets/portraits/skorpios.jpg";
import flogobollon from "../../assets/portraits/flogobollon.jpg";
import clockworkHoplites from "../../assets/portraits/clockwork-hoplites.jpg";
import andromachos from "../../assets/portraits/andromachos.jpg";
import sacredBand from "../../assets/portraits/sacred-band.jpg";
import sacredBandOfficer from "../../assets/portraits/sacred-band-officer.jpg";

import sorcerersDefault from "../../assets/portraits/sorcerers-default.png";
import sorcererKing from "../../assets/portraits/sorcerer.jpg";
import efreetFlamecasters from "../../assets/portraits/efreet-flamecasters.jpg";
import efreetSwordDancers from "../../assets/portraits/efreet-sword-dancers.jpg";
import ghols from "../../assets/portraits/ghols.jpg";
import maharajah from "../../assets/portraits/maharajah.jpg";
import raj from "../../assets/portraits/raj.jpg";
import rajakur from "../../assets/portraits/rajakur.jpg";
import sardar from "../../assets/portraits/sardar.jpg";
import ravanar from "../../assets/portraits/rakshasa-ravanar.jpg";
import bakasura from "../../assets/portraits/rakshasa-bakasura.jpg";
import steelheartDjinn from "../../assets/portraits/steelheart-djinn.jpg";
import windborneDjinn from "../../assets/portraits/windborne-djinn.jpg";
import dhanurDisciples from "../../assets/portraits/dhanur-disciples.jpg";
import mahut from "../../assets/portraits/mahut.jpg";


import Officers from "../../components/list-entry/options/Officers";


const FB_SETTINGS = {
	logo: fbLogo,
	icon: (
		<SvgIcon
			component={ConquestIcon}
			sx={{
				"& .background": {
					color: red[900]
				}
			}}
		/>
	),
	iconLight: (
		<SvgIcon
			component={ConquestIcon}
			sx={{
				"& .background": {
					color: "transparent"
				},
				"& .foreground": {
					color: red[900]
				}
			}}
		/>
	),
	entryCountIcon: baseIcon,
	entryUnit: "Model",
	entryUnitPlural: "Models",
	listDefaultPoints: 1000,
	listPointsStep: 10,
	listMinPoints: 200,
	tint: red[300],
	directives: FB_DIRECTIVES,
	qrcodes: QRCODES,
	qrDirectives: QR_DIRECTIVES,
	targets: FB_TARGETS,
	effects: FB_EFFECTS,
	rules: FB_RULES,
	components: {
		pages: {
			home: MainGameMenu,
			factions: FactionsGrid,
			faction: FactionLayout,
			editor: ListLayout,
		},
		panels: {
			faction: FactionPanel,
			entry: EntryPanel,
			list: ListPanel,
			"list-entry": ListEntryPanel,
		},
		"faction-options": {},
		"entry-options": {
			default: DetailedOptions,
			heirlooms: DescriptionOnly,
			mutations: DescriptionOnly,
			"trove-finds": DescriptionOnly,
			"artefacts": DescriptionOnly,
			"treasures": DescriptionOnly,
			"bestowed-relics": DescriptionOnly,
			"relics": DescriptionOnly,
			"patron-s-gifts": DescriptionOnly,
			retinue: Retinue,
		},
		"list-entry-options": {
			default: DetailedList,
			"retinue": RetinueFB,
			"officers": Officers,
		},
	},

	factions: {
		"the-hundred-kingdoms": {
			bgr: dominionBgr,
			cardBgr: hundredCardBgr,
			icon: HundredIcon,
			description:
				"Sheltered by the selfless sacrifices of the last Legion, the refugees that managed to escape the cataclysm of the Fall would survive the Long Winter that followed and, in time, prosper to found one of the largest and most diverse bastions of human civilization on Eä....",
			websiteUrl: "https://www.para-bellum.com/the-hundred-kingdoms/",
			defaultEntryIcon: hundredDefault,
		},
		"the-spires": {
			bgr: dominionBgr,
			cardBgr: spiresCardBgr,
			icon: SpiresIcon,
			description:
				"Scattered throughout the land as if by the hand of a mad god, the Spires dominate the landscape they stand upon. Rising thousands of feet into the air, they are the source of countless suppositions, tales and myths....",
			websiteUrl: "https://www.para-bellum.com/the-spires/",
			defaultEntryIcon: spiresDefault,
		},
		"dweghom": {
			bgr: dominionBgr,
			cardBgr: dweghomCardBgr,
			icon: DweghomIcon,
			description:
				"The Dweghom are the firstborn children of War and their long history is steeped in warfare and loss. Originally crafted by the dragons as a race of peerless craftsmen, their Ancestors were forged to be the ultimate servitors; hardy, industrious, loyal and dedicated to the creation of beauty....",
			websiteUrl: "https://www.para-bellum.com/the-dweghom/",
			defaultEntryIcon: dweghomDefault,
		},
		"nords": {
			bgr: dominionBgr,
			cardBgr: nordsCardBgr,
			icon: NordsIcon,
			description:
				"Whereas the Hundred Kingdoms and the City States might claim to be the main bastions of human civilization, one should not assume that this means they are the sole bastions of human might....",
			websiteUrl: "https://www.para-bellum.com/the-nords/",
			defaultEntryIcon: nordsDefault,
		},
		"the-wadrhun": {
			bgr: dominionBgr,
			cardBgr: wadrhunCardBgr,
			icon: WadrhunIcon,
			description:
				"For centuries, the tales of nomadic savages with giant, pre-dominion beasts that dwell beyond the edges of civilization have been a source of entertainment, delight and lazy, abstract deliberations amongst self-titled scholars and historians....",
			websiteUrl: "https://www.para-bellum.com/wadrhun/",
			defaultEntryIcon: wadrhunDefault,
		},
		"the-old-dominion": {
			bgr: dominionBgr,
			cardBgr: dominionCardBgr,
			icon: DominionIcon,
			description:
				"A common misconception amongst modern historians and their students is that the title of Dominion refers to the Dominion of Man. It most certainly does not. The original inhabitants referred instead to the Dominion of Hazlia, the Pantokrator, God of Mankind. And with his Fall the Dominion ended – but did not die...",
			websiteUrl: "https://www.para-bellum.com/the-old-dominion/",
			defaultEntryIcon: dominionDefault,
		},
		"the-city-states": {
			bgr: dominionBgr,
			cardBgr: statesCardBgr,
			icon: StatesIcon,
			description:
				"While the Hundred Kingdoms were being born in a wave of desperate refugees, violence and hunger, the City States were flourishing, picking through the greatest secrets of the old Dominion and delving into the secrets of archemy and divinity...",
			websiteUrl: "https://www.para-bellum.com/the-city-states/",
			defaultEntryIcon: statesDefault,
		},
		"sorcerer-kings": {
			bgr: dominionBgr,
			cardBgr: sorcerersCardBgr,
			icon: SorcerersIcon,
			description:
				"Far to the south and east of the lands of the Hundred Kingdoms and the City States lie lands that exist on no map known to mankind, even the wide-ranging exploration fleets of the Old Dominion, for they quite simply did not exist at that time...",
			websiteUrl: "https://www.para-bellum.com/the-sorcerer-kings/",
			defaultEntryIcon: sorcerersDefault,
		},
	},

	entries: {
		// 100K
		"chapter-mage": { icon: chapterMage },
		"gilded-legion": { icon: gildedLegion },
		"household-guard": { icon: householdGuard },
		"household-knights": { icon: householdKnights },
		"hunter-cadre": { icon: hunterCadre },
		"imperial-officer": { icon: imperialOfficer },
		"longbowmen": { icon: longbowmen },
		"men-at-arms": { icon: menAtArms },
		"mercenary-crossbowmen": { icon: crossbowmen },
		"militia": { icon: militia },
		"militia-bowmen": { icon: militiaBowmen },
		"mounted-noble-lord": { icon: mountedNobleLord },
		"mounted-squires": { icon: mountedSquires },
		"mounted-squire-retinue": { icon: mountedSquires },
		"noble-lord": { icon: nobleLord },
		"steel-legion": { icon: steelLegion },
		"theist-priest": { icon: theistPriest },
		"armsmaster": { icon: armsmaster },
		"drillmaster": { icon: drillmaster },
		"seasoned-veteran": { icon: seasonedVeteran },
		"servite": { icon: servite },
		"null-mage": { icon: nullMage },
		"priory-commander": { icon: prioryTower },
		"order-of-the-crimson-tower": { icon: orderTower },
		"order-of-the-ashen-dawn": { icon: orderDawn },
		"imperial-ranger-corps": { icon: imperialRangers },
		"errant-of-the-order-of-the-shield": { icon: errantShield },
		"neophyte": { icon: neophyte },
		"order-of-the-sealed-temple": { icon: sealedTemple },

		// Spires
		"centaur-avatara": { icon: centaur },
		"force-grown-drones": { icon: forceGrown },
		"high-clone-executor": { icon: executor },
		"incarnate-sentinels": { icon: sentinel },
		"lineage-highborne": { icon: highborn },
		"marksman-clones": { icon: marksman },
		"mimetic-assassin": { icon: assassin },
		"pheromancer": { icon: pheromancer },
		"stryx": { icon: stryx },
		"vanguard-clones": { icon: vanguard },
		"vanguard-clone-infiltrators": { icon: vanguardInfiltrators },
		"ward-preceptor": { icon: preceptor },
		"abomination": { icon: abomination },
		"assault-preceptor": { icon: assault },
		"avatara": { icon: avatara },
		"biomancer": { icon: biomancer },
		"brute-drones": { icon: bruteDrones },
		"catabolic-node": { icon: catabolic },
		"onslaught-drones": { icon: onslaughtDrones },
		"bound-clones": { icon: boundClones },
		"siegebreaker-behemoth": { icon: siegebreaker },
		"leonine-avatara": { icon: leonineAvatara },
		"lineage-prideborne": { icon: lineagePrideborne },
		"desolation-drones": { icon: desolationDrones },

		// Dweghom
		"hold-warriors": { icon: holdWarriors },
		"inferno-automata": { icon: automata },
		"initiates-shields": { icon: initiates },
		"initiates-pikes": { icon: initiates },
		"tempered-sorcerer": { icon: sorcerer },
		"tempered-steelshaper": { icon: steelshaper },
		"wardens": { icon: wardens },
		"ardent-kerawegh": { icon: kerawegh },
		"dragonslayers": { icon: dragonslayers },
		"fireforged": { icon: fireforged },
		"flame-berserkers": { icon: berserkers },
		"flamecaster": { icon: flamecaster },
		"hellbringer-drake": { icon: drake },
		"hellbringer-sorcerer": { icon: hellbringerSorcerer },
		"herald-of-fire": { icon: heraldFire },
		"herald-of-stone": { icon: heraldStone },
		"herald-of-magma": { icon: heraldMagma },
		"hold-ballistae": { icon: ballistae },
		"hold-raegh": { icon: raegh },
		"hold-thanes": { icon: thanes },
		"exemplar": { icon: exemplar },
		"mnemancer-apprentice": { icon: apprentice },
		"steelforged": { icon: steelforged },
		"bergont-raegh": { icon: bergontRaegh },
		"ironclad-drake": { icon: ironcladDrake },
		"magmaforged": { icon: magmaforged },
		"stoneforged": { icon: stoneforged },

		// Nords
		"ugr": { icon: ugr },
		"blooded": { icon: blooded },
		"captain": { icon: captain },
		"huskarls": { icon: huskarls },
		"jarl": { icon: jarl },
		"mountain-jotnar": { icon: mountainJotnar },
		"fenr": { icon: fenr },
		"ice-jotnar": { icon: iceJotnar },
		"konungyr": { icon: konungyr },
		"skald": { icon: skald },
		"trolls": { icon: trolls },
		"raiders": { icon: raiders },
		"shaman": { icon: shaman },
		"shield-biter": { icon: shieldBiter },
		"stalkers": { icon: stalkers },
		"blooded-crow": { icon: blooded },
		"blooded-wolf": { icon: blooded },
		"valkyries": { icon: valkyries },
		"fenr-beastpack": { icon: fenr },
		"bow-chosen": { icon: bowChosen },
		"volva": { icon: volva },
		"sea-jotnar": { icon: seaJotnar },
		"ulfhednar": { icon: ulfhednar },
		"bearsarks": { icon: bearsarks },
		"thegn": { icon: thegn },
		"werewargs": { icon: werewolves },
		"vargyr-lord": { icon: vargyrLord },
		"savage": { icon: savage },
		"jotnar-seidr": { icon: prometheanSeidr },

		// Wadrhun
		"matriarch-queen": { icon: queen },
		"predator": { icon: predator },
		"raptor-riders": { icon: raptors },
		"scion-of-conquest": { icon: scion },
		"slingers": { icon: slingers },
		"warbred": { icon: warbred },
		"aberration": { icon: aberration },
		"apex-predator": { icon: apexPredator },
		"blooded-wadrhun": { icon: bloodedWadrhun },
		"braves": { icon: braves },
		"hunters": { icon: hunters },
		"hunting-pack": { icon: huntingPack },
		"veterans": { icon: veterans },
		"chieftain": { icon: chieftain },
		"apex-queen": { icon: apexQueen },
		"chosen-of-conquest": { icon: chosenConquest },
		"thunder-chieftain": { icon: thunderChieftain },
		"thunder-riders": { icon: thunderRiders },

		// Old Dominion
		"archimandrite": { icon: archimandrite },
		"bone-golems": { icon: golems },
		"fallen-divinity": { icon: divinity },
		"hierodeacon": { icon: hierodeacon },
		"kataphraktoi": { icon: kataphraktoi },
		"kheres": { icon: kheres },
		"moroi": { icon: moroi },
		"optio": { icon: optio },
		"praetorian-guard": { icon: praetorian },
		"strategos": { icon: strategos },
		"xhiliarch": { icon: xhiliarch },
		"dark-cenotaph": { icon: darkCenotaph },
		"legionnaires": { icon: legionnaires },
		"mounted-strategos": { icon: mountedStrategos },
		"kanephors": { icon: kanephors },
		"karyatids": { icon: karyatids },
		"athanatoi": { icon: athanatoi },
		"varangian-guard": { icon: varangianGuard },
		"profane-reliquary": { icon: reliquary },
		"hetairos": { icon: hetairos },
		"buccephaloi": { icon: buccephaloi },
		"cultists": { icon: cultists },
		"hashashin": { icon: hashashin },
		"centaur-kerykes": { icon: centaurKerykes },
		"centaur-prodromoi": { icon: centaurProdromoi },

		// City States
		"aristarch": { icon: aristarch },
		"polemarch": { icon: polemarch },
		"hoplites": { icon: hoplites },
		"phalangites": { icon: phalangites },
		"minotaur-haspists": { icon: haspists },
		"minotaur-thyreans": { icon: thyreans },
		"agema": { icon: agema },
		"hephaestian": { icon: hephaestian },
		"mechanist": { icon: mechanist },
		"promethean": { icon: promethean },
		"thorakites": { icon: thorakites },
		"dorilates": { icon: dorilates },
		"lochagos": { icon: lochagos },
		"eidolon": { icon: eidolon },
		"inquisitors": { icon: inquisitors },
		"satyroi": { icon: satyroi },
		"selinoi": { icon: selinoi },
		"companion-cavalry": { icon: companionCavalry },
		"ipparchos": { icon: ipparchos },
		"talos": { icon: talos },
		"skorpios": { icon: skorpios },
		"flogobollon": { icon: flogobollon },
		"clockwork-hoplites": { icon: clockworkHoplites },
		"andromachos": { icon: andromachos },
		"sacred-band": { icon: sacredBand },
		"sacred-band-officer": { icon: sacredBandOfficer },
		"promethean-oracle": { icon: prometheanSeidr },

		// Sorcerer Kings
		"sorcerer": { icon: sorcererKing },
		"efreet-flamecasters": { icon: efreetFlamecasters },
		"efreet-sword-dancers": { icon: efreetSwordDancers },
		"ghols": { icon: ghols },
		"maharajah": { icon: maharajah },
		"raj": { icon: raj },
		"rajakur": { icon: rajakur },
		"sardar": { icon: sardar },
		"rakshasa-bakasura": { icon: bakasura },
		"rakshasa-ravanar": { icon: ravanar },
		"steelheart-djinn": { icon: steelheartDjinn },
		"windborne-djinn": { icon: windborneDjinn },
		"dhanur-disciples": { icon: dhanurDisciples },
		"mahut": { icon: mahut },
	},
};


export default FB_SETTINGS;
