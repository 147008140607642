import { Button, Stack, Typography, Link as MuiLink } from "@mui/material";
import { Link, useNavigation } from "react-router-dom";
import FrameBox from "../../ui/containers/FrameBox";
import { Box, styled } from "@mui/system";
import CenteredContentBox from "../../ui/containers/CenteredContentBox";
import { ReactComponent as Separator }
	from "../../assets/decorative/separator-hor.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useDispatch } from "react-redux";
import { openScanner, openListSetup } from "../../store/utils-slice";
import useGameSettings from "../../hooks/use-game-settings";
import Loading from "../../ui/Loading";
import RippedBox from "../../ui/containers/RippedBox";
import AppPromo from "../AppPromo";
import FrameDialog from "../../ui/containers/FrameDialog";
import FrameHeader from "../../ui/containers/FrameHeader";
import FrameDialogContent from "../../ui/containers/FrameDialogContent";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const GameLogo = styled("img")(({ theme }) => ({
	height: "90px",
	[theme.breakpoints.only("xs")]: {
		height: "70px",
	},
}));


const Line = styled(Separator)(({ theme }) => ({
	height: "40px",
	fill: "currentColor",
	padding: theme.spacing(0, 0, 3, 0),
}));


function MainGameMenu({ game }) {
	const { slug, name } = game;
	const { getGameSettings } = useGameSettings();
	const { logo } = getGameSettings(slug);
	const dispatch = useDispatch();
	const { state } = useNavigation();
	const [newsOpen, setNewsOpen] = useState(false);

	function handleNewList() {
		dispatch(openListSetup());
	}

	function handleScannerOpen() {
		dispatch(openScanner());
	}

	if (state === "loading") {
		return (
			<CenteredContentBox>
				<Loading />
			</CenteredContentBox>
		);
	}

	return (
		<>
			<CenteredContentBox>
				<Stack spacing={2} alignItems="center">
					<Button
						variant="contained"
						size="large"
						onClick={() => setNewsOpen(true)}
						startIcon={<NewReleasesIcon />}
						sx={{width: 230, marginBottom: 2}}
					>
						News
					</Button>
					<FrameBox
						frameStyle="frame1"
						bgcolor="darkBgr.main"
						color="darkBgr.contrastText"
					>
						<Stack spacing={2} m={{ xs: 5, sm: 9 }}>
							<GameLogo src={logo} alt={name} />
							<Line />
							<Button
								component={Link}
								to="factions"
								variant="contained"
								size="large"
							>
								Browse Factions
							</Button>
							<Button variant="contained" size="large" onClick={handleNewList}>
								Prepare for War
							</Button>
							<Button
								variant="contained"
								size="large"
								onClick={handleScannerOpen}
								startIcon={<QrCodeScannerIcon />}
							>
								Scan Card
							</Button>
						</Stack>
					</FrameBox>
					<Box pt={4}>
						<AppPromo />
					</Box>
				</Stack>
			</CenteredContentBox>
			<FrameDialog
				open={newsOpen}
				responsive
				onClose={() => setNewsOpen(false)}
				actions={[
					{
						id: "Cancel",
						icon: CloseIcon,
						handler: () => setNewsOpen(false),
					}
				]}
			>
				<FrameHeader title="News" />
				<FrameDialogContent>
					<Typography gutterBottom>
						Season 3 Finals are around the corner - but Season 4 is only just beginning! We welcome this new season with a host of new updates and releases, starting with the Promethean Seidr!
					</Typography>
					<Typography gutterBottom>
						The Promethean Seidr - a <strong>[TITAN]</strong>, stay tuned for our Sunday sneak peeks for more on that - is a Character leading the giant forces of City States and Nords available for both Conquest TLAOK and First Blood!
					</Typography>
					<Typography gutterBottom>
						With a selection of two weapons - reflecting the different tactics the Seidr can employ - and separate abilities as well as Supremacy abilities depending on what faction the Seidr is leading you have a chance at focusing your game around your favorite Giants. Who are you going to lead? The Children of the North or those of Fire?
					</Typography>
					<Typography>
						Make sure to check the rules preview on the App!
					</Typography>
				</FrameDialogContent>
			</FrameDialog>
		</>
	);
}


export default MainGameMenu;